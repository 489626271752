<template>
  <BaseLayout>
    <div id="content">
      <div class="container">
        <header class="mb-4">
          <div class="row">
            <div class="col-md mb-4 mb-md-5">
              <h1 class="theme-text">Admin Dashboard</h1>
            </div>
          </div>
        </header>
        <!-- stats graph -->
        <section class="stats">
          <div class="row">
            <div class="col-md-3">
              <div class="wrapper">
                <span>{{ totalSearch }}</span>
                <p>Product Searches (weekly)</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrapper">
                <span>{{ totalCompare }}</span>
                <p>Products Compared (weekly)</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrapper">
                <span>&pound; {{ totalWorthOfProductEmailed }}</span>
                <p>Worth of products emailed</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrapper">
                <span>{{ totalEmailed }}</span>
                <p>Emailed (weekly)</p>
              </div>
            </div>

            <div class="col-md-12">
              <div class="wrapper">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <h2>Statistics</h2>
                  <form action="">
                    <select
                      name="MonthFilterValue"
                      v-model="monthFilterValue"
                      @change="onChangeFilter()"
                    >
                      <option value="3">Last 3 months</option>
                      <option value="6">Last 6 months</option>
                      <option value="12">Last 12 months</option>
                    </select>
                  </form>
                </div>
                <!-- <line-chart></line-chart> -->
                <bar-chart
                  v-if="loaded"
                  :chartdata="chartdata"
                  :options="options"
                />
                <!-- <img
                    src="./../../assets/images/stats.png"
                    class="w-100"
                    alt="stats"
                  /> -->
              </div>
            </div>
          </div>
        </section>
        <!-- /stats graph -->

        <!-- tabular stats -->
        <section class="tabular-stats">
          <header>
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-3 mb-3 mb-lg-0">
                <h2>Engineer Statistics</h2>
              </div>
              <div class="col-lg-8">
                <div class="filter-date">
                  <span>Filter by Date</span>
                  <b-form-datepicker
                    id="datepicker-placeholder1"
                    weekday-header-format="short"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      weekday: 'short',
                    }"
                    placeholder="From"
                    locale="en"
                    v-model="filterDates.fromDate"
                  ></b-form-datepicker>
                  <b-form-datepicker
                    id="datepicker-placeholder2"
                    weekday-header-format="short"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      weekday: 'short',
                    }"
                    placeholder="To"
                    locale="en"
                    v-model="filterDates.toDate"
                  ></b-form-datepicker>
                  <button
                    type="submit"
                    @click="getUserStats()"
                    :disabled="loading"
                    class="btn btn-primary"
                  >
                    Search
                  </button>
                  <a
                    v-if="filterDates.fromDate == null"
                    href="https://admin.bshappliancesdirect.co.uk/api/user_stat/export_user_stat"
                    class="btn btn-primary"
                    >Export</a
                  >
                  <a
                    v-else
                    :href="
                      `https://admin.bshappliancesdirect.co.uk/api/user_stat/export_user_stat/?timestamp_lte=${filterDates.toDate}&timestamp_gte=${filterDates.fromDate}`
                    "
                    class="btn btn-primary"
                    >Export</a
                  >
                </div>
              </div>
            </div>
          </header>
          <div v-if="loading" class="table-loading">
            <p>
              Loading
              <span
                v-show="loading"
                class="spinner-grow spinner-grow-sm"
                style="width: 1.0625rem; height: 1.0625rem"
                role="status"
                aria-hidden="true"
              ></span>
            </p>
          </div>
          <div v-else class="table-responsive">
            <table class="table1">
              <thead>
                <tr>
                  <th scope="col">Engineer Name</th>
                  <th scope="col">Engineer Email</th>
                  <th scope="col">Employee Number</th>
                  <th scope="col">Profile Type</th>
                  <th scope="col">No. of Products Searched</th>
                  <th scope="col">No. of Emails Sent</th>
                  <th scope="col">No. of Products Compared</th>
                  <th scope="col">Product ID’s Searched</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(stat, index) in userStat" :key="index">
                  <td>{{ stat.full_name }}</td>
                  <td>
                    <a :href="`mailto:${stat.email}`">{{ stat.email }}</a>
                  </td>
                  <td>{{ stat.employee_number }}</td>
                  <td>{{ stat.profile_type }}</td>
                  <td>{{ stat.user_details.product_search }}</td>
                  <td>{{ stat.user_details.total_emails }}</td>
                  <td>{{ stat.user_details.product_compare }}</td>
                  <td>
                    <b-button v-b-modal="'modal' + index">Click Here</b-button>

                    <b-modal
                      :id="'modal' + index"
                      modal-class="custom-modal"
                      centered
                      hide-footer
                    >
                      <template #modal-title>
                        Product ID’s Searched by -
                        <span>{{ stat.full_name }}</span>
                      </template>

                      <p>
                        <span
                          v-for="(pId, index) in stat.user_details
                            .product_id_searched"
                          :key="index"
                        >
                          {{ pId }}
                        </span>
                      </p>
                    </b-modal>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @change="pageChanged"
            :disabled="loading"
          >
          </b-pagination>
        </section>
        <!-- /tabular stats -->
      </div>
    </div>
  </BaseLayout>
</template>
<script>
import BaseLayout from "@/layouts/base";
import BarChart from "@/components/BarChart";
import StatisticService from "@/services/statistic.service";
import UserstatsService from "@/services/userstats.service";
export default {
  name: "Admin",
  components: {
    BaseLayout,
    BarChart,
  },

  data: function() {
    return {
      totalSearch: 0,
      totalCompare: 0,
      totalWorthOfProductEmailed: 0,
      totalEmailed: 0,
      loaded: false,
      chartdata: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      monthsLabel: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      totalSearchMonthly: [],
      totalProductEmailedMonthly: [],
      totalCompareMonthly: [],
      totalEmailMonthly: [],
      monthFilterValue: 3,
      filterDates: {
        fromDate: null,
        toDate: null,
      },
      userStat: [],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      loading: false,
    };
  },
  mounted() {
    this.getWeeklyTotalSearch();
    this.getWeeklyTotalCompare();
    this.getWeeklyTotalEmailProductWorth();
    this.getWeeklyTotalEmail();
    this.getMonthlyChartData(this.monthFilterValue);
    this.getUserStats();
  },
  methods: {
    getWeeklyTotalSearch: function() {
      StatisticService.getTotalSearch(
        this.getCurrentTime(),
        this.getPreviousWeekTime(),
        "weekly",
        "staff"
      ).then((response) => {
        this.totalSearch = response.total_search;
      });
    },
    getWeeklyTotalCompare: function() {
      StatisticService.getTotalCompare(
        this.getCurrentTime(),
        this.getPreviousWeekTime(),
        "weekly",
        "staff"
      ).then((response) => {
        this.totalCompare = response.total_compare;
      });
    },
    getWeeklyTotalEmailProductWorth: function() {
      StatisticService.getTotalEmailProductWorth(
        this.getCurrentTime(),
        this.getPreviousWeekTime(),
        "staff"
      ).then((response) => {
        this.totalWorthOfProductEmailed = response.total_price;
      });
    },
    getWeeklyTotalEmail: function() {
      StatisticService.getTotalEmailed(
        this.getCurrentTime(),
        this.getPreviousWeekTime(),
        "weekly",
        "staff"
      ).then((response) => {
        this.totalEmailed = response.total_emailed;
      });
    },
    getCurrentTime: function() {
      return new Date();
    },
    getPreviousWeekTime: function() {
      var previousWeekTime = new Date();
      previousWeekTime.setDate(previousWeekTime.getDate() - 7);
      return previousWeekTime;
    },
    getPreviousmonths: function(numberOfMonths) {
      var previousMonthsTime = new Date();
      var diffInMonths = numberOfMonths - 1;
      previousMonthsTime.setMonth(previousMonthsTime.getMonth() - diffInMonths);
      previousMonthsTime.setDate(1);
      return previousMonthsTime;
    },
    getMonthlyTotalSearch: function(numberOfMonths) {
      StatisticService.getTotalSearch(
        this.getCurrentTime(),
        this.getPreviousmonths(numberOfMonths),
        "monthly",
        "staff"
      ).then((response) => {
        this.totalSearchMonthly = response.total_search;

        if (this.totalSearchMonthly) {
          this.data1 = this.manipulateMonthData(
            this.totalSearchMonthly,
            numberOfMonths
          );
        }
      });
    },
    getMonthlyTotalCompare: function(numberOfMonths) {
      StatisticService.getTotalCompare(
        this.getCurrentTime(),
        this.getPreviousmonths(numberOfMonths),
        "monthly",
        "staff"
      ).then((response) => {
        this.totalCompareMonthly = response.total_compare;

        if (this.totalCompareMonthly) {
          this.data2 = this.manipulateMonthData(
            this.totalCompareMonthly,
            numberOfMonths
          );
        }
      });
    },

    getMonthlyTotalProductEmailedMonthly: function(numberOfMonths) {
      StatisticService.getTotalProductEmailed(
        this.getCurrentTime(),
        this.getPreviousmonths(numberOfMonths),
        "monthly",
        "staff"
      ).then((response) => {
        this.totalProductEmailedMonthly = response.total_product_emailed;

        if (this.totalProductEmailedMonthly) {
          this.data3 = this.manipulateMonthData(
            this.totalProductEmailedMonthly,
            numberOfMonths
          );
        }
      });
    },

    getMonthlyTotalEmailed: function(numberOfMonths) {
      StatisticService.getTotalEmailed(
        this.getCurrentTime(),
        this.getPreviousmonths(numberOfMonths),
        "monthly",
        "staff"
      ).then((response) => {
        this.totalEmailMonthly = response.total_emailed;

        if (this.totalEmailMonthly) {
          this.data4 = this.manipulateMonthData(
            this.totalEmailMonthly,
            numberOfMonths
          );
          this.data3 = this.manipulateMonthData(
            this.totalProductEmailedMonthly,
            numberOfMonths
          );
          this.data2 = this.manipulateMonthData(
            this.totalCompareMonthly,
            numberOfMonths
          );
          this.data1 = this.manipulateMonthData(
            this.totalSearchMonthly,
            numberOfMonths
          );
        }
        setTimeout(() => this.generateChart(), 1000);
      });
    },
    generateChart: function() {
      this.loaded = true;
      this.chartdata = {
        labels: this.monthsLabel,
        datasets: [
          {
            label: "Number of product Search",
            data: this.data1,
            backgroundColor: "green",
            borderColor: "rgb(201, 203, 207)",
            borderWidth: 1,
          },
          {
            label: "Number of Product Compared",
            data: this.data2,
            backgroundColor: "blue",
            borderColor: "rgb(201, 203, 207)",
            borderWidth: 1,
          },
          {
            label: "Number of Product emailed",
            data: this.data3,
            backgroundColor: "red ",
            borderColor: "rgb(201, 203, 207)",
            borderWidth: 1,
          },
          {
            label: "Number of emailed",
            data: this.data4,
            backgroundColor: "yellow ",
            borderColor: "rgb(201, 203, 207)",
            borderWidth: 1,
          },
        ],
      };
    },
    getMonthlyChartData: function(numberOfMonths) {
      this.monthsLabel = this.getMonthLabels(numberOfMonths);
      this.getMonthlyTotalSearch(numberOfMonths);
      this.getMonthlyTotalCompare(numberOfMonths);
      this.getMonthlyTotalProductEmailedMonthly(numberOfMonths);
      this.getMonthlyTotalEmailed(numberOfMonths);
    },
    getMonthLabels: function(numberOfMonths) {
      var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var today = new Date();
      var lastMonths = [];

      for (var i = 0; i < numberOfMonths; i++) {
        var count = today.getMonth() - i;
        if (count < 0) {
          count = 12 + count;
        }
        lastMonths.push(monthNames[count]);
      }
      return lastMonths.reverse();
    },
    manipulateMonthData: function(datasets, numberOfMonths) {
      var data = [];
      data.push(0);
      var today = new Date();
      for (var i = 0; i < numberOfMonths; i++) {
        datasets.forEach(async function(dataset) {
          if (dataset["month"] == today.getMonth() + 1 - i) {
            data[i + 1] = dataset["c"];
          } else {
            if (data[i + 1]) {
              data[i + 1] = data[i + 1];
            } else {
              data[i + 1] = 0;
            }
          }
        });
      }
      return data.reverse();
    },
    onChangeFilter: function() {
      this.loaded = false;
      this.chartdata = null;
      this.getMonthlyChartData(this.monthFilterValue);
    },

    getUserStats: function() {
      this.loading = true;
      UserstatsService.list(this.filterDates, this.currentPage).then(
        (response) => {
          this.userStat = response.results;
          this.rows = response.count;
          this.loading = false;
        }
      );
    },

    pageChanged(page) {
      this.loading = true;
      this.currentPage = page;
      this.getUserStats();
    },
  },
};
</script>

<style scoped></style>

import axios from 'axios';

class UserstatsService {

  async list(filterDates, currentPage) {
    const response = await axios
      .get('api/user_stat/', {
        params: {
          timestamp_gte: filterDates.fromDate,
          timestamp_lte: filterDates.toDate,
          page: currentPage,
        }
      });
    return response.data;
  }

}


export default new UserstatsService();
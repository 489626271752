var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("div", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "mb-4" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md mb-4 mb-md-5" }, [
              _c("h1", { staticClass: "theme-text" }, [
                _vm._v("Admin Dashboard")
              ])
            ])
          ])
        ]),
        _c("section", { staticClass: "stats" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("span", [_vm._v(_vm._s(_vm.totalSearch))]),
                _c("p", [_vm._v("Product Searches (weekly)")])
              ])
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("span", [_vm._v(_vm._s(_vm.totalCompare))]),
                _c("p", [_vm._v("Products Compared (weekly)")])
              ])
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("span", [
                  _vm._v("£ " + _vm._s(_vm.totalWorthOfProductEmailed))
                ]),
                _c("p", [_vm._v("Worth of products emailed")])
              ])
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("span", [_vm._v(_vm._s(_vm.totalEmailed))]),
                _c("p", [_vm._v("Emailed (weekly)")])
              ])
            ]),
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "wrapper" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between mb-3"
                    },
                    [
                      _c("h2", [_vm._v("Statistics")]),
                      _c("form", { attrs: { action: "" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.monthFilterValue,
                                expression: "monthFilterValue"
                              }
                            ],
                            attrs: { name: "MonthFilterValue" },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.monthFilterValue = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.onChangeFilter()
                                }
                              ]
                            }
                          },
                          [
                            _c("option", { attrs: { value: "3" } }, [
                              _vm._v("Last 3 months")
                            ]),
                            _c("option", { attrs: { value: "6" } }, [
                              _vm._v("Last 6 months")
                            ]),
                            _c("option", { attrs: { value: "12" } }, [
                              _vm._v("Last 12 months")
                            ])
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm.loaded
                    ? _c("bar-chart", {
                        attrs: {
                          chartdata: _vm.chartdata,
                          options: _vm.options
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]),
        _c(
          "section",
          { staticClass: "tabular-stats" },
          [
            _c("header", [
              _c(
                "div",
                {
                  staticClass: "row justify-content-between align-items-center"
                },
                [
                  _c("div", { staticClass: "col-lg-3 mb-3 mb-lg-0" }, [
                    _c("h2", [_vm._v("Engineer Statistics")])
                  ]),
                  _c("div", { staticClass: "col-lg-8" }, [
                    _c(
                      "div",
                      { staticClass: "filter-date" },
                      [
                        _c("span", [_vm._v("Filter by Date")]),
                        _c("b-form-datepicker", {
                          attrs: {
                            id: "datepicker-placeholder1",
                            "weekday-header-format": "short",
                            "date-format-options": {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              weekday: "short"
                            },
                            placeholder: "From",
                            locale: "en"
                          },
                          model: {
                            value: _vm.filterDates.fromDate,
                            callback: function($$v) {
                              _vm.$set(_vm.filterDates, "fromDate", $$v)
                            },
                            expression: "filterDates.fromDate"
                          }
                        }),
                        _c("b-form-datepicker", {
                          attrs: {
                            id: "datepicker-placeholder2",
                            "weekday-header-format": "short",
                            "date-format-options": {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              weekday: "short"
                            },
                            placeholder: "To",
                            locale: "en"
                          },
                          model: {
                            value: _vm.filterDates.toDate,
                            callback: function($$v) {
                              _vm.$set(_vm.filterDates, "toDate", $$v)
                            },
                            expression: "filterDates.toDate"
                          }
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "submit", disabled: _vm.loading },
                            on: {
                              click: function($event) {
                                return _vm.getUserStats()
                              }
                            }
                          },
                          [_vm._v(" Search ")]
                        ),
                        _vm.filterDates.fromDate == null
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  href:
                                    "https://admin.bshappliancesdirect.co.uk/api/user_stat/export_user_stat"
                                }
                              },
                              [_vm._v("Export")]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  href:
                                    "https://admin.bshappliancesdirect.co.uk/api/user_stat/export_user_stat/?timestamp_lte=" +
                                    _vm.filterDates.toDate +
                                    "&timestamp_gte=" +
                                    _vm.filterDates.fromDate
                                }
                              },
                              [_vm._v("Export")]
                            )
                      ],
                      1
                    )
                  ])
                ]
              )
            ]),
            _vm.loading
              ? _c("div", { staticClass: "table-loading" }, [
                  _c("p", [
                    _vm._v(" Loading "),
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "spinner-grow spinner-grow-sm",
                      staticStyle: { width: "1.0625rem", height: "1.0625rem" },
                      attrs: { role: "status", "aria-hidden": "true" }
                    })
                  ])
                ])
              : _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table1" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("Engineer Name")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("Engineer Email")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("Employee Number")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("Profile Type")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("No. of Products Searched")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("No. of Emails Sent")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("No. of Products Compared")
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("Product ID’s Searched")
                        ])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.userStat, function(stat, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(stat.full_name))]),
                          _c("td", [
                            _c(
                              "a",
                              { attrs: { href: "mailto:" + stat.email } },
                              [_vm._v(_vm._s(stat.email))]
                            )
                          ]),
                          _c("td", [_vm._v(_vm._s(stat.employee_number))]),
                          _c("td", [_vm._v(_vm._s(stat.profile_type))]),
                          _c("td", [
                            _vm._v(_vm._s(stat.user_details.product_search))
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(stat.user_details.total_emails))
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(stat.user_details.product_compare))
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal",
                                      value: "modal" + index,
                                      expression: "'modal' + index"
                                    }
                                  ]
                                },
                                [_vm._v("Click Here")]
                              ),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id: "modal" + index,
                                    "modal-class": "custom-modal",
                                    centered: "",
                                    "hide-footer": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "modal-title",
                                        fn: function() {
                                          return [
                                            _vm._v(
                                              " Product ID’s Searched by - "
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(stat.full_name))
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "p",
                                    _vm._l(
                                      stat.user_details.product_id_searched,
                                      function(pId, index) {
                                        return _c("span", { key: index }, [
                                          _vm._v(" " + _vm._s(pId) + " ")
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]),
            _c("b-pagination", {
              attrs: {
                "total-rows": _vm.rows,
                "per-page": _vm.perPage,
                disabled: _vm.loading
              },
              on: { change: _vm.pageChanged },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }